const englishConfig = {
  addr: {
    name: "Gouripur Govt. College",
    local: "Gouripur, Mymensingh",
    est: "Est-1964",
    code: "Code-5212",
    eiin:"EIIN-englishConfig"
  },
  email: "ggcmym1964@gmail.com",
  phone:"",
  contact:"Contact: 09024 56042",

  principalNm:"Prof. Md. Abdul Hamid",
  principalDesig:"Principal",
  principalJobId:"ID-07564",

  vicePrincipalNm:"Prof. Md. Abdul Qadir Fakir",  
  vicePrincipalDesig:"Vice Principal",
  vicePrincipalJobId:"ID-05799"


};
export default englishConfig;
