import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './homebodySidebar';
import "../../assets/css/headerCustom.css";
import aboutusimg from '../../assets/img/homebody/aboutus.png'
import administration from '../../assets/img/homebody/administration.png'
import admission from '../../assets/img/homebody/admission.png'
import Academic from '../../assets/img/homebody/Academic.png'
import result from '../../assets/img/homebody/result.png'
import Cocurricular from '../../assets/img/homebody/CoCurricular.png'
class homebody extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
          <div className="ltn__blog-area mb-120">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="ltn__blog-list-wrap">
                    {/* row Item */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div
                          style={{ backgroundColor: "#ededed" }}
                          className="ltn__blog-item ltn__blog-item-5 go-top homeBodydiv"
                        >
                          <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">
                              About GGC
                            </h4>
                            <ul>
                              <li>
                                <div className="top-rated-product-item clearfix go-top">
                                  <div className="top-rated-product-img">
                                    <Link to="#">
                                      <img src={aboutusimg} alt="#" />
                                    </Link>
                                  </div>
                                  <div className="top-rated-product-info">
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        At a Glance{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        History{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Why Study at GGC{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Mission & Vision{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Rules & Regulation{" "}
                                      </Link>{" "}
                                    </li>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div
                          style={{ backgroundColor: "#ededed" }}
                          className="ltn__blog-item ltn__blog-item-5 go-top homeBodydiv"
                        >
                          <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">
                              Administration
                            </h4>
                            <ul>
                              <li>
                                <div className="top-rated-product-item clearfix go-top">
                                  <div className="top-rated-product-img">
                                    <Link to="#">
                                      <img src={administration} alt="#" />
                                    </Link>
                                  </div>

                                  <div className="top-rated-product-info">
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Message From Principal{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Governing body{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Teachers info
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>{" "}
                                        Staffs
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        PTA{" "}
                                      </Link>{" "}
                                    </li>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div
                          style={{ backgroundColor: "#ededed" }}
                          className="ltn__blog-item ltn__blog-item-5 go-top homeBodydiv"
                        >
                          <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">
                              Admission
                            </h4>
                            <ul>
                              <li>
                                <div className="top-rated-product-item clearfix go-top">
                                  <div className="top-rated-product-img">
                                    <Link to="#">
                                      <img src={admission} alt="#" />
                                    </Link>
                                  </div>
                                  <div className="top-rated-product-info">
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Admission Notice{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Admission Form{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Selected List{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Fees & Payments
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Payment Methods{" "}
                                      </Link>{" "}
                                    </li>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div
                          style={{ backgroundColor: "#ededed" }}
                          className="ltn__blog-item ltn__blog-item-5 go-top homeBodydiv"
                        >
                          <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">
                              Academic
                            </h4>
                            <ul>
                              <li>
                                <div className="top-rated-product-item clearfix go-top">
                                  <div className="top-rated-product-img">
                                    <Link to="#">
                                      <img src={Academic} alt="#" />
                                    </Link>
                                  </div>

                                  <div className="top-rated-product-info">
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        HSC{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Degree{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        MS (Preli.)
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Hons
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        MS{" "}
                                      </Link>{" "}
                                    </li>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div
                          style={{ backgroundColor: "#ededed" }}
                          className="ltn__blog-item ltn__blog-item-5 go-top homeBodydiv"
                        >
                          <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">
                              Evaluation & Result
                            </h4>
                            <ul>
                              <li>
                                <div className="top-rated-product-item clearfix go-top">
                                  <div className="top-rated-product-img">
                                    <Link to="#">
                                      <img src={result} alt="#" />
                                    </Link>
                                  </div>
                                  <div className="top-rated-product-info">
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Exam Systems{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Evaluation Methods{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Internal Result{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Board Result
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Re-Examine
                                      </Link>{" "}
                                    </li>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div
                          style={{ backgroundColor: "#ededed" }}
                          className="ltn__blog-item ltn__blog-item-5 go-top homeBodydiv"
                        >
                          <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border-2">
                              Co Curricular
                            </h4>
                            <ul>
                              <li>
                                <div className="top-rated-product-item clearfix go-top">
                                  <div className="top-rated-product-img">
                                    <Link to="#">
                                      <img src={Cocurricular} alt="#" />
                                    </Link>
                                  </div>

                                  <div className="top-rated-product-info">
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Debating Club{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Science Club{" "}
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Computer Club
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Blood donation club
                                      </Link>{" "}
                                    </li>
                                    <li
                                      style={{
                                        marginTop: "0px",
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        color: "maroon",
                                      }}
                                    >
                                      {" "}
                                      <Link to="#">
                                        <i class="fa-sharp fa-solid fa-circle-plus"></i>
                                        Cultural club{" "}
                                      </Link>{" "}
                                    </li>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* end row Item */}

                    {/* Blog Item (No Image) */}
                    <div
                      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-15 pb-20"
                      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                                Feeling Good
                              </h6>
                              <h1 className="section-title">Notable Alumni</h1>
                            </div>
                          </div>
                        </div>
                        <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
                          <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                              <div className="ltn__testimoni-info">
                                <p>
                                  <i className="flaticon-left-quote-1" />
                                  Precious ipsum dolor sit amet consectetur
                                  adipisicing elit, sed dos
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                  <div className="ltn__testimoni-img">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/testimonial/1.jpg"
                                      }
                                      alt="#"
                                    />
                                  </div>
                                  <div className="ltn__testimoni-name-designation">
                                    <h5>Dr. Arif Hayat Khan Pathan</h5>
                                    <label>Serjon</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                              <div className="ltn__testimoni-info">
                                <p>
                                  <i className="flaticon-left-quote-1" />
                                  Precious ipsum dolor sit amet consectetur
                                  adipisicing elit, sed dos
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                  <div className="ltn__testimoni-img">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/testimonial/2.jpg"
                                      }
                                      alt="#"
                                    />
                                  </div>
                                  <div className="ltn__testimoni-name-designation">
                                    <h5>Lt.</h5>
                                    <label>Selling Agents</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                              <div className="ltn__testimoni-info">
                                <p>
                                  <i className="flaticon-left-quote-1" />
                                  Precious ipsum dolor sit amet consectetur
                                  adipisicing elit, sed dos
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                  <div className="ltn__testimoni-img">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/testimonial/3.jpg"
                                      }
                                      alt="#"
                                    />
                                  </div>
                                  <div className="ltn__testimoni-name-designation">
                                    <h5>Adam Joseph</h5>
                                    <label>Selling Agents</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="ltn__testimonial-item ltn__testimonial-item-7">
                              <div className="ltn__testimoni-info">
                                <p>
                                  <i className="flaticon-left-quote-1" />
                                  Precious ipsum dolor sit amet consectetur
                                  adipisicing elit, sed dos
                                </p>
                                <div className="ltn__testimoni-info-inner">
                                  <div className="ltn__testimoni-img">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/testimonial/4.jpg"
                                      }
                                      alt="#"
                                    />
                                  </div>
                                  <div className="ltn__testimoni-name-designation">
                                    <h5>James Carter</h5>
                                    <label>Selling Agents</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>

                    {/* Blog Item (Background Image) */}
                    <div className="ltn__blog-item ltn__blog-item-5 ltn__blog-item-bg-image bg-image bg-overlay-white-90">
                      <div className="ltn__blog-brief go-top">
                        <div className="ltn__blog-meta">
                          <ul>
                            <li className="ltn__blog-category">
                              <Link to="/blog-grid">Find Us On Google Map</Link>
                            </li>
                          </ul>
                        </div>

                        <div className="ltn__blog-meta mb-0">
                          <div className="google-map mb-120">
                            <iframe
                              width="850"
                              height="600"
                              id="gmap_canvas"
                              src="https://maps.google.com/maps?q=govt+gouripur+college&t=&z=11&ie=UTF8&iwloc=&output=embed"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              allowFullScreen
                              aria-hidden="false"
                              tabIndex={0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>
                <Sidebar />
              </div>
            </div>
          </div>
        );
    }
}

export default homebody;
